@import "../../../../Styles/master";

#apparel {
  #apparel-header {
    @include headerImage;
    #apparel-headerText {
      @include headerText;
    }
  }

  #apparel-about {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @include pageOverviewText;
  }

  #apparel-search {
    padding-top: 0.5rem;
    @include search;

    &:hover {
      #apparel-searchIcon,
      #apparel-searchInput {
        box-shadow: 0 0 3px 2px $primary;
      }
    }

    #apparel-searchIcon {
      @include searchIcon;
    }

    #apparel-searchInput {
      @include searchBar;
    }

    #apparel-resultCount {
      @include searchResults;
    }
  }

  #apparel-noResults {
    @include noResults;
  }

  #apparel-listings {
    display: flex;
    flex-wrap: wrap;
    background-size: 100vw auto;

    .apparel-category {
      flex-basis: 90%;
      margin: auto;
      background-color: $image-overlay;

      .apparel-categoryName {
        @include categoryTitle;

        @include responsive(mobile) {
          font-size: $text-large;
        }
      }
      .apparel-categoryDescription {
        padding-bottom: 0.25rem;
        @include categoryDescription;
      }
      .apparel-categoryListings {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0.25rem;

        .apparel-link {
          display: flex;
          flex-basis: 33%;

          @include responsive(widescreen) {
            flex-basis: 25%;
          }

          @include responsive(tablet) {
            flex-basis: 50%;
          }

          @include responsive(mobile) {
            flex-basis: 100%;
          }

          .apparel-listing {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0.25rem;
            overflow: hidden;
            transition: background-size 0.2s linear;
            background-color: white;
            color: $image-text;
            font-family: $subheading-font;
            font-size: $text-standard;
            font-weight: bold;
            text-decoration: none;
            text-align: center;
            @include imagesMain;

            &:hover {
              background-size: 125%;
              .apparel-apparelTitle {
                background-color: $primary;
              }
            }

            .apparel-apparelTitle {
              width: 100%;
              padding: 0.1rem;
              padding-left: 0.25rem;
              padding-right: 0.25rem;
              transition: background-color 0.2s linear;
              @include outline(black, 1px, 3px);
            }

            .apparel-new {
              position: absolute;
              top: 0;
              right: 0;
              padding: 0.1rem;
              background-color: $primary;
              font-size: $text-small;
            }
          }
        }
      }
    }
  }
}
