@import "../../../Styles/master";

#footer {
  border-top: 4px solid $secondary2;
  background-image: url("../../../Media/images/wood.jpg");
  background-size: 100% auto;
  color: $secondary2;
}

#footerLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .footer-logoLink {
    display: flex;
    justify-content: center;
    flex-basis: 40%;
    height: auto;
    overflow: hidden;

    @include responsive(tabletSmall) {
      flex-basis: 70%;
    }
  }

  #footer-logo {
    height: auto;
    min-height: 2rem;
    max-height: 7rem;
    max-width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    object-fit: contain;
  }
}
#footer-linksContainer {
  display: flex;
  justify-content: center;

  #footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
    font-family: $link-font;
    font-size: $text-standard;
    list-style: none;

    .footer-linkContainer {
      display: block;
      min-width: 4rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      box-sizing: border-box;
      text-align: center;

      @include responsive(mobile) {
        flex-basis: 100%;
      }

      .footer-link {
        display: block;
        width: 100%;
        transition: all 0.3s linear;
        color: black;
        font-size: 1rem;
        text-decoration: none;
        letter-spacing: 1px;
        @include outline($secondary2, 2px, 3px);

        &.footer-link-active,
        &:hover {
          color: $secondary2;
          @include outline(white, 2px, 3px);
        }
      }
    }
  }
}

#footer-info {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0.25rem;

  @include responsive(tablet) {
    justify-content: center;
    height: auto;
    padding-top: 0.25rem !important;

    #footer-socialMedia {
      flex-basis: 100%;
      padding-left: 0.25rem;
    }

    #footer-copyright {
      padding-bottom: 0.25rem;
      text-align: center;

      @include responsive(tablet) {
        padding-top: 0.25rem;
      }
    }

    #footer-siteBy {
      flex-basis: 100%;
      margin-left: 0 !important;
      text-align: center;
    }
  }

  #footer-socialMedia {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  #footer-facebookLink,
  #footer-instagramLink {
    padding-right: 0.5rem;
    #footer-instagramLogo,
    #footer-facebookLogo {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;

      &:hover {
        box-shadow: 0 0 5px 1px white;
      }
    }
  }

  #footer-copyright {
    font-family: $text-font;
    font-size: $text-small;
    font-weight: bold;
    @include outline(black, 2px, 3px);
  }

  #footer-facebookLink {
    padding-right: 0.5rem;
    #footer-facebookLogo {
      height: 1rem;
      width: 1rem;
    }
  }

  #footer-siteBy {
    margin-left: auto;
    color: $accent-text;
    text-decoration: none;
  }
}
