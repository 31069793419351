@import "../../../../Styles/master";

#recipes-header {
  @include headerImage;
  #recipes-headerText {
    @include headerText;
  }
}

#recipes-about {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @include pageOverviewText;
}

#recipes-search {
  @include search;

  #recipes-searchIcon {
    @include searchIcon;
  }

  #recipes-searchInput {
    @include searchBar;
  }

  #recipes-resultCount {
    @include searchResults;
  }
}

#recipes-noResults {
  @include noResults;
}

#recipes-list {
  display: flex;
  flex-wrap: wrap;
  background-size: 100vw auto;

  #recipes-cards {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    background-color: $image-overlay;

    @include responsive(mobile) {
      width: 100%;
    }
  }

  .recipes-recipeLink {
    flex-basis: 100%;
    max-width: 100%;

    @include responsive(widescreen) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  .recipes-recipe {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 100%;
    position: relative;
    height: 14rem;
    margin: 0.5rem;
    border: 2px solid $primary;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: white;

    .recipes-recipeTitle {
      padding: 0.25rem;
      color: $primary;
      @include postTitle;
      @include outline(black, 1px, 3px);
    }

    .recipes-recipePicture {
      flex-basis: 100%;
      height: 11rem;
      background-position: center;
      background-size: cover;
    }

    .recipes-ingredients,
    .recipes-instructions {
      flex-basis: 100%;
      box-sizing: border-box;
      overflow: hidden;
      color: $secondary-text;
    }
    .recipes-ingredientsList,
    .recipes-instructionsList {
      margin: 0;
      padding-top: 0.25rem;
    }

    .recipes-ingredientsTitle,
    .recipes-instructionsTitle {
      padding-left: 1rem;
      font-family: $subheading-font;
      font-size: $text-standard;
    }

    .recipes-ingredient,
    .recipes-instruction {
      padding-bottom: 0.25rem;
      padding-right: 0.5rem;
      font-family: $text-font;
      font-size: $text-standard;
      text-align: left;

      @include responsive(mobile) {
        font-size: $text-small;
      }
    }

    .recipes-recipeOverview {
      flex-basis: 100%;
      height: 1rem;
      margin: 0.25rem;
      overflow: hidden;
      color: $secondary-text;
      font-family: $text-font;
      font-size: $text-standard;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include responsive(mobile) {
        font-size: $text-small;
      }
    }

    .recipes-readMore {
      @include readMore;
    }
  }
}
