@import "../../../Styles/master";

.recipe {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.recipe-header {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  flex-basis: 100%;

  @include responsive(mobile) {
    padding-bottom: 0.25rem;
  }

  .recipe-backLink {
    text-decoration: none;
    .recipe-back {
      @include backButton;

      .recipe-backIcon {
        font-size: $text-large;
        color: $primary-text;
      }
    }
  }

  .recipe-date {
    @include dateWithBackButton;
  }
}

.recipe-title {
  @include itemTitle;
}

.recipe-overview {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  flex-basis: 100%;

  @include responsive(widescreen) {
    margin-left: 5%;
    margin-right: 5%;
  }

  .recipe-overviewParagraph {
    @include paragraphText;
  }
}

.recipe-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 100%;

  @include responsive(widescreen) {
    flex-basis: 90%;
  }
}

#recipe-images {
  @include imagesContainer;

  .recipe-imageMain {
    @include imagesMain;
  }

  .recipe-imageTiles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-basis: 100%;

    .recipe-imageTile {
      @include imagesTiles;
    }
  }
}

.recipe-directions {
  flex-basis: 50%;

  @include responsive(tabletSmall) {
    flex-basis: 95%;
  }

  @include responsive(widescreen) {
    flex-basis: 60%;
  }

  &.recipe-directionsFullWidth {
    flex-basis: 95% !important;

    .recipe-ingredientsHeader,
    .recipe-instructionsHeader {
      font-weight: bold;
      text-align: left;
      padding-left: 1rem;
    }
  }
}

.recipe-ingredients {
  padding-bottom: 0.5rem;
}

.recipe-fullWidth {
  flex-basis: 100%;
}

.recipe-ingredients,
.recipe-instructions {
  .recipe-listComponentFullWidth {
    height: 0px;
    color: $secondary;
    overflow: hidden;
    position: absolute;
  }
}

.recipe-ingredients,
.recipe-instructions,
.recipe-fullWidth {
  text-align: center;
  font-family: $subheading-font;
  font-size: $text-large;
  font-weight: bold;

  @include responsive(mobile) {
    font-size: $text-standard;
  }

  @include responsive(widescreen) {
    .recipe-ingredientsHeader,
    .recipe-instructionsHeader {
      text-align: left;
      padding-left: 1rem;
    }
  }

  .recipe-ingredientsList,
  .recipe-instructionsList {
    @include instructionList;
  }

  .recipe-ingredient,
  .recipe-instruction,
  .recipe-listItem {
    font-family: $text-font;
    font-size: $text-standard;
    text-align: left;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;

    @include responsive(mobile) {
      font-size: $text-small;
    }
  }
}

.recipe-tags {
  display: none;
}

.recipe-fullWidth {
  padding-bottom: 1rem;
  flex-basis: 95%;
}
