@import "../../../../Styles/master";

#about-header {
  @include headerImage;

  #about-headerText {
    @include headerText;
  }
  margin-bottom: 4px;
}

#about {
  padding-bottom: 1rem;
  background-image: url("../../../../Media/images/wood.jpg");
}

.about-woodBackground {
  @include wood-size;

  #about-text {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include pageOverviewText;

    .about-textParagraph {
      &:not(:last-child) {
        padding-bottom: 1rem;
      }
    }
  }
}
