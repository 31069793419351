@import "../../../Styles/master";

.provision {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 1rem;
}

.provision-header {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  flex-basis: 100%;

  @include responsive(tabletSmall) {
    justify-content: center;
    padding-bottom: 0.25rem;
    padding-top: 0.5rem;
  }

  .provision-backLink {
    text-decoration: none;

    .provision-back {
      @include backButton;

      .provision-backIcon {
        font-size: $text-large;
        color: $primary-text;
      }
    }
  }
}

.provision-title {
  @include itemTitle;
  padding-bottom: 0.25rem;
}

.provision-content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: center;
  font-family: $text-font;
  font-size: $text-standard;

  @include responsive(widescreen) {
    flex-basis: 90%;
  }

  .provision-description {
    box-sizing: border-box;
    flex-basis: 90%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    @include responsive(tabletSmall) {
      flex-basis: 95%;
    }

    .provision-paragraph {
      @include paragraphText;
    }
  }

  .provision-images {
    @include imagesContainer;

    @include responsive(widescreen) {
      flex-basis: 50% !important;
      padding-left: 10%;
    }

    .provision-imageMain {
      @include imagesMain;
    }

    .provision-imageTiles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-basis: 100%;

      .provision-imageTile {
        @include imagesTiles;
      }
    }
  }

  .provision-about {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-basis: 50%;
    padding-left: 0.5rem;
    overflow: hidden;

    @include responsive(tabletSmall) {
      flex-basis: 95%;
    }

    @include responsive(widescreen) {
      padding-left: 1rem;
    }

    .provision-ingredients {
      font-family: $subheading-font;
      padding-top: 0.5rem;
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      .provision-ingredientsList {
        font-family: $text-font;
        padding-top: 0.25rem;
        flex-basis: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-size: $text-small;
        text-decoration: none;
      }
    }

    .provision-price {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .provision-quantityInput {
        box-sizing: border-box;
        height: 1.25rem;
        padding-left: 0.25rem;
        margin-left: 0.5rem;
        width: 1.5rem;
      }
    }

    .provision-options {
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;

      .provision-option {
        padding-right: 0.5rem;
        &:not(.provision-optionSelected):hover {
          cursor: pointer;
        }

        &.provision-optionSelected {
          color: $primary;
        }
      }
    }

    .provision-addToCart {
      .provision-addButton {
        @include addButton;
        height: 1.25rem;
        margin-left: 0.5rem;
      }
    }

    .provision-notesContainer {
      padding-top: 0.5rem;
      font-family: $subheading-font;
      .provision-notes {
        padding-top: 0.25rem;
        font-size: $text-small;
        font-family: $text-font;
      }
    }
  }
}

.provision-tags {
  display: none;
}
