@import "../../../Styles//master";

#promotionsBar {
  min-height: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: $primary;
  color: $primary-text;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: $text-font;
  font-size: $text-small;

  @include responsive(tablet) {
    font-size: $text-tiny;
  }
}
