@import "../../../Styles/master";

.apparel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 1rem;
}

.apparel-header {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  flex-basis: 100%;

  @include responsive(tabletSmall) {
    justify-content: center;
    padding-bottom: 0.25rem;
    padding-top: 0.5rem;
  }

  .apparel-backLink {
    text-decoration: none;

    .apparel-back {
      @include backButton;

      .apparel-backIcon {
        font-size: $text-large;
        color: $primary-text;
      }
    }
  }
}

.apparel-title {
  @include itemTitle;
  padding-bottom: 0.25rem;
}

.apparel-content {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: center;
  font-family: $text-font;
  font-size: $text-standard;

  @include responsive(widescreen) {
    flex-basis: 90%;
  }

  .apparel-description {
    box-sizing: border-box;
    flex-basis: 90%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    @include responsive(tabletSmall) {
      flex-basis: 95%;
    }

    .apparel-paragraph {
      @include paragraphText;
    }
  }

  .apparel-images {
    @include imagesContainer;

    @include responsive(widescreen) {
      flex-basis: 50% !important;
      padding-left: 10%;
    }

    .apparel-imageMain {
      @include imagesMain;
    }

    .apparel-imageTiles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-basis: 100%;

      .apparel-imageTile {
        @include imagesTiles;
      }
    }
  }
}

.apparel-about {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-basis: 50%;
  padding-left: 0.5rem;
  overflow: hidden;

  @include responsive(tabletSmall) {
    flex-basis: 95%;
  }

  @include responsive(widescreen) {
    padding-left: 1rem;
  }

  .apparel-price {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .apparel-quantityInput {
      box-sizing: border-box;
      height: 1.25rem;
      padding-left: 0.25rem;
      margin-left: 0.5rem;
      width: 1.5rem;
    }

    .apparel-addButton {
      @include addButton;
      height: 1.25rem;
      margin-left: 0.5rem;
    }
  }

  .apparel-options {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;

    .apparel-option {
      padding-right: 0.5rem;

      &:not(.apparel-optionSelected):hover {
        cursor: pointer;
      }

      &.apparel-optionSelected {
        color: $primary;
      }
    }
  }

  .apparel-notesContainer {
    padding-top: 0.5rem;
    font-family: $subheading-font;

    .apparel-notes {
      font-family: $text-font;
      padding-top: 0.25rem;
      font-size: $text-small;
    }
  }
}
