html {
  font-size: 32px;
}

#root {
  margin-bottom: -1.25rem;
}

button:not(.provision-addButton):not(.apparel-addButton) {
  margin: 0;
  padding: 0;
  height: 0px;
  overflow: hidden;
  border: none;
}

html,
body {
  margin: 0px;
  padding: 0px;
}
