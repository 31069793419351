@import "../../../Styles/master";

.location {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: hidden;

  .location-backBar {
    display: flex;
    align-items: center;
    padding-top: 0.25rem;
    flex-basis: 100%;

    @include responsive(tabletSmall) {
      justify-content: center;
      padding-bottom: 0.25rem;
      padding-top: 0.5rem;
    }

    .location-backLink {
      text-decoration: none;

      .location-back {
        @include backButton;

        .location-backIcon {
          font-size: $text-large;
          color: $primary-text;
        }
      }
    }
  }

  .location-header {
    font-size: $text-header;
    font-family: $heading-font;
    color: $primary;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-basis: 100%;

    @include responsive(tabletSmall) {
      font-size: $text-section-header;
    }
  }

  .location-title {
    font-size: $text-section-header;
    font-family: $subheading-font;
    color: $secondary-text;
    text-align: center;
    flex-basis: 100%;

    @include responsive(tabletSmall) {
      font-size: $text-large;
    }
  }

  .location-store {
    box-sizing: border-box;
    padding-bottom: 0.5rem;
    flex-basis: 100%;

    .location-link {
      text-decoration: none;
    }

    .location-storeName {
      @include itemTitle;
      font-size: $text-large;
      padding-bottom: 0.25rem;
    }

    .location-info {
      font-family: $text-font;
      font-size: $text-standard;
      color: $secondary-text;
      padding-bottom: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @include responsive(mobile) {
        font-size: $text-small;
      }

      .location-infoIcon {
        font-size: $text-large;
        color: $primary;

        @include responsive(mobile) {
          font-size: $text-standard;
        }
      }
    }
  }
}
