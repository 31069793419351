@import "../../../Styles/master";

$screwAngle: 41deg;
$menuWidth: 3rem;
$menuHeight: 2.25rem;
$icon-height: 0.35rem;
$transitionSpeed: 0.5s;
$pepper-padding: 0.45rem;
$pepper-offset: 0.1rem;

@keyframes slideFade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.menu {
  background-color: black !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  width: $menuWidth;
  transition-timing-function: ease-in-out;
  box-sizing: border-box;

  .menu-icon {
    position: absolute;
    transition: opacity, transform;
    transition-duration: $transitionSpeed / 2, $transitionSpeed;
    width: auto;
    height: $icon-height;
    flex-basis: 100%;
    transition-timing-function: ease-in-out;
  }

  .menu-pepperTop {
    padding-top: $pepper-offset;
    transform-origin: left top;
  }

  .menu-pepperBottom {
    padding-top: 2 * $pepper-padding + $pepper-offset;
    transform-origin: left bottom;
  }

  .menu-pepperMiddle {
    transform: rotateY(180deg);
    padding-top: $pepper-padding + $pepper-offset;
  }

  .menu-pepperTop,
  .menu-pepperBottom {
    animation-name: slideFade;
    animation-duration: $transitionSpeed;
    animation-direction: reverse;
  }

  .menu-pepper {
    opacity: 0;
  }

  .menu-pepperRight {
    transform-origin: left top;
    padding-right: 2rem;
    padding-top: 3 * $pepper-padding + $pepper-offset;
  }

  .menu-pepperLeft {
    transform-origin: left bottom;
  }

  .menu-pepper {
    position: absolute;
  }

  &.menu-open {
    .menu-pepperTop {
      transform: translateY($menuHeight / 2);
      opacity: 0;
    }

    .menu-pepperBottom {
      transform: translateY(-$menuHeight / 2);
      opacity: 0;
    }

    .menu-pepperMiddle {
      opacity: 0;
    }

    .menu-pepperTop,
    .menu-pepperBottom {
      animation-name: slideFade;
      animation-duration: $transitionSpeed;
      animation-direction: forward;
    }

    .menu-pepper {
      opacity: 1;
    }

    .menu-pepperRight {
      transform-origin: left top;
      transform: rotate(-$screwAngle);
      padding-top: 3 * $pepper-padding + $pepper-offset;
      padding-right: 2rem;
    }

    .menu-pepperLeft {
      transform-origin: left bottom;
      transform: rotate($screwAngle);
      // padding-top: $pepper-offset;
    }
  }
}
