@import "../../../Styles/master";

.shop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: $primary;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  color: black;
  font-weight: bold;
  @include outline(white, 1px, 3px);

  .shop-title {
    flex-basis: 100%;
    font-family: $subheading-font;
    font-size: $text-section-header;
    padding-bottom: 1rem;
    text-align: center;
  }

  .shop-item {
    font-size: $text-large;
    font-family: $text-font;
    color: $accent-text;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;

    .shop-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7rem;
      height: 7rem;
      background-color: black;
      border-radius: 50%;
      box-shadow: 0 0 2px 2px white;
      margin-bottom: 1rem;
    }

    @include responsive(mobile) {
      flex-basis: 100%;

      &:not(:last-child) {
        padding-bottom: 2rem;
      }
    }

    @include responsive(widescreen) {
      flex-basis: 50%;
    }

    @include responsive(mobile) {
      flex-basis: 100%;
    }

    .shop-link {
      text-decoration: none;

      .shop-icon {
        // width: 4.5rem;
        max-height: 70%;
        clear: both;

        &.shop-provisions {
          max-height: 85%;
        }
      }

      .shop-itemName {
        flex-basis: 100%;
        color: black;
      }
    }
  }
}
