@import "../../../../Styles/master";

#find-header {
  margin-bottom: 4px;
  @include headerImage;

  #find-headerText {
    @include headerText;
  }
}

#find {
  background-image: url("../../../../Media/images/wood.jpg");
}

.find-wood {
  background-color: $secondary3;
  @include wood-size;
}

#find-events {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  color: $primary;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;
  text-align: center;

  .find-event {
    flex-basis: 100%;
    padding-top: 0.5rem;
    box-sizing: border-box;
    color: $secondary-text;
    font-family: $text-font;
    font-size: $text-standard;
    font-weight: normal;

    @include responsive(desktop) {
      padding-left: 5%;
      padding-right: 5%;
    }

    @include responsive(widescreen) {
      padding-left: 10%;
      padding-right: 10%;
    }

    .find-eventTitle {
      color: $primary;
      font-size: $text-large;
      font-weight: bold;

      .find-eventDate {
        display: inline;
        padding-left: 0.5rem;
        color: $secondary-text;
        font-size: $text-standard;
        font-weight: normal;

        @include responsive(mobile) {
          display: block;
        }
      }
    }

    .find-eventLink {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

#find-locations {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  color: $primary;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;
  text-align: center;

  #find-locationsSubheader {
    flex-basis: 100%;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: $secondary-text;
    font-family: $text-font;
    font-size: $text-standard;
    font-weight: normal;
  }

  .find-locationLink {
    flex-basis: 100%;
    padding-top: 0.5rem;
    color: $secondary-text;
    font-size: $text-large;
    text-decoration: none;

    &:hover {
      color: $primary;
    }
  }
}

#find-contact {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: $primary;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;
  text-align: center;

  .find-contactMethod {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 100%;
    padding-bottom: 0.5rem;
    color: $secondary-text;
    font-family: $text-font;
    font-size: $text-standard;
    font-weight: normal;
    text-decoration: none;

    @include responsive(mobile) {
      font-size: $text-small;
    }

    &:hover:not(#find-address) {
      color: $primary;
    }

    &:first-child {
      padding-top: 0.5rem;
    }

    .find-contactIcon {
      padding-right: 0.25rem;
      color: $primary;
      font-size: $text-large;

      @include responsive(mobile) {
        font-size: $text-standard;
      }
    }
  }
}

#find-map {
  flex-basis: 100%;
  height: 40vh;
  width: 100%;
  margin: 0;
  margin-bottom: -0.3rem;
  padding: 0;
  border: none;

  @include responsive(tabletSmall) {
    height: 100vw;
  }
}
