@import "../../../Styles/master";

$arrowSize: 1.5rem;

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

#carousel-container {
  height: 80vh;

  @include responsive(tablet) {
    height: 70vw;
  }
}

#carousel-container {
  position: relative;
  background-repeat: no-repeat;

  #carousel-textContainer {
    z-index: 1;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    #carousel-text {
      @include headerText;
    }
  }

  #carousel-image {
    height: 100%;
    background-repeat: no-repeat;

    &.carousel-imageFade {
      animation-name: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .carousel-arrow {
      animation: fade-in;
      animation-duration: 1s;
    }
  }

  &:not(:hover) {
    .carousel-arrow {
      animation: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  .carousel-arrow {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: $arrowSize;
    height: $arrowSize;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-$arrowSize / 2);
    cursor: pointer;

    @include responsive(tabletSmall) {
      display: none;
    }
  }

  #carousel-arrowRight {
    right: 1rem;
  }

  #carousel-arrowLeft {
    left: 1rem;
  }
}
