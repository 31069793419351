@import "../../../Styles/master.scss";

$linkHeight: 1.25rem;
$stickyHeight: 2rem;

#nav-navigationSpacer {
  height: 5rem + $linkHeight;

  @include responsive(tablet) {
    height: 5rem + 1.75rem;
  }

  @include responsive(tabletSmall) {
    height: 4rem + 1.75rem;
  }
}

.nav-linksSlidedown,
.nav-stickyLinksSlidedown {
  transition-duration: 0.3s;
}

#nav-navigation,
#nav-stickyNav {
  box-shadow: 0 0 4px 1px $accent;
  position: absolute;
  width: 100%;
  z-index: 1000;

  .nav-menuContainer {
    height: 1.5rem;
    display: none;
    justify-content: center;
    align-items: center;
    color: $secondary-text;
    border-bottom: 5px ridge $secondary2;
    background-color: black;
    .nav-menuIcon {
      height: 100%;
      cursor: pointer;
      display: none;
      color: $secondary2;
    }
  }

  .nav-linksSlidedown {
    overflow: hidden;

    .nav-links {
      margin: 0;
      padding: 0;

      .nav-linkContainer {
        list-style: none;
        min-height: $linkHeight;

        .nav-link {
          display: inline-flex;
          text-decoration: none;
          font-family: $link-font;
          font-size: $text-standard;
          color: $secondary-text;
          height: $linkHeight;

          .nav-linkText {
            transition: all 0.3s linear;
            display: inline-flex;
            font-weight: bold;
            align-items: center;
            letter-spacing: 1.75px;
            @include outline($secondary2, 1px, 3px);

            &:hover {
              color: white;
            }
          }

          &.nav-link-active {
            .nav-linkText {
              @include outline($secondary2, 1px, 3px);
              color: white;
            }
          }
        }
      }
    }
  }
}

.nav-stickyMenu-container,
.nav-stickyMenuIcon {
  background-color: black;
}

#nav-navigation {
  background-color: $secondary2;

  #nav-logoHomeLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    #nav-logo {
      height: 5rem;

      @include responsive(tabletSmall) {
        height: 4rem;
      }
    }
  }

  .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: black;
    border-bottom: 5px ridge $secondary2;

    .nav-linkContainer {
      &:not(:first-child) {
        padding-left: 0.75rem;
      }

      @include responsive(desktop) {
        &:not(:first-child) {
          padding-left: 1.25rem;
        }
      }

      @include responsive(widescreen) {
        &:not(:first-child) {
          padding-left: 2rem;
        }
      }
    }
  }

  @include responsive(tablet) {
    .nav-menuContainer {
      display: flex;
      font-size: $icon-standard;
      .nav-menuIcon {
        display: inline;
      }
    }

    .nav-links {
      flex-wrap: wrap;
      background-color: black;

      .nav-linkContainer {
        width: 100%;
        min-height: $linkHeight;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0 !important;

        &:not(:first-child) {
          border-top: 2px solid $primary;
        }

        .nav-link {
          &:not(:hover):not(.nav-link-active) {
            color: black;
          }
          width: 100%;
          height: $linkHeight;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

#nav-stickyNav {
  background-color: black;
  height: $stickyHeight;
  width: 100%;
  position: fixed;
  transition: top 0.3s linear;
  top: -$stickyHeight - 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.nav-stickyNavShown {
    top: 0;
  }

  #nav-stickyLogoHomeLink {
    height: $stickyHeight;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 5%;
    // flex-grow: 1;

    #nav-stickyLogo {
      width: auto;
      height: $stickyHeight - 0.25rem;
    }
  }

  .nav-links {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $stickyHeight;
    box-sizing: border-box;

    .nav-linkContainer {
      padding-left: 0.5rem;

      @include responsive(widescreen) {
        padding-left: 1rem;
      }
    }
  }

  @include responsive(stickyLinksCutoff) {
    flex-wrap: wrap;

    .nav-menuContainer {
      border: none;
      position: absolute;
      right: 0.25rem;
      display: inline-flex;
      font-size: $icon-standard;
      background-color: $secondary2;

      .nav-menuIcon {
        color: black !important;
      }

      @include responsive(mobileSmall) {
        right: 0.1rem;
      }
      .nav-menuIcon {
        display: inline;
      }
    }

    .nav-linksSlidedown {
      width: 100%;

      .nav-links {
        flex-wrap: wrap;
        width: 100%;
        background-color: black;
        height: auto;

        .nav-linkContainer {
          padding: 0;
          margin: 0;
          width: 100%;
          height: $linkHeight;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            border-top: 4px solid black;
          }

          &:not(:last-child) {
            border-bottom: 2px solid $primary;
          }

          &:last-child {
            border-bottom: 5px ridge $secondary2;
          }

          .nav-link {
            width: 100%;
            height: $linkHeight;
            align-items: center;
            justify-content: center;

            &:not(:hover):not(.nav-link-active) {
            }
            &.nav-link-active {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
