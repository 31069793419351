@import "../../../Styles/master";

#info-infoBar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 1.5rem;
  background-image: url("../../../Media/images/wood.jpg");
  background-color: $accent;
  color: $secondary2;
  font-family: $text-font;
  font-size: $text-small;
  font-weight: bold;

  @include responsive(tablet) {
    justify-content: center;
    font-size: $text-tiny;

    #info-email {
      padding-right: 0rem;
    }

    #info-phone {
      margin-left: 0;
    }
  }

  #info-facebookLink,
  #info-instagramLink {
    padding: 0.25rem;

    #info-facebookLogo,
    #info-instagramLogo {
      border-radius: 50%;

      &:hover {
        box-shadow: 0 0 5px 1px white;
        cursor: pointer;
      }
    }
  }

  #info-facebookLink,
  #info-instagramLink,
  .info-socialMediaIcon {
    height: 1rem;
    width: 1rem;
  }

  #info-phone,
  #info-email {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    transition: all 0.25s linear;
    color: $primary;
    font-weight: bold;
    text-decoration: none;
    @include outline(black, 0px, 3px);

    &:hover {
      color: $primary;
      @include outline(black, 0px, 6px);
      .info-infoIcon {
        color: $primary;
      }
    }

    .info-infoIcon {
      padding-right: 0.25rem;
      transition: all 0.25s linear;
      color: #ebebeb;
      font-size: $icon-standard;
    }
  }

  #info-phone {
    margin-left: auto;
  }
}
