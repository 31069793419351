@import "../../../../Styles/master";

#provisions-header {
  @include headerImage;
  #provisions-headerText {
    @include headerText;
  }
}

#provisions-about {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @include pageOverviewText;
}

#provisions-search {
  padding-top: 0.5rem;
  @include search;

  &:hover {
    #provisions-searchIcon,
    #provisions-searchInput {
      box-shadow: 0 0 3px 2px $primary;
    }
  }

  #provisions-searchIcon {
    @include searchIcon;
  }

  #provisions-searchInput {
    @include searchBar;
  }

  #provisions-resultCount {
    @include searchResults;
  }
}

#provisions-noResults {
  @include noResults;
}

#provisions-listings {
  display: flex;
  flex-wrap: wrap;
  background-size: 100vw auto;

  .provisions-category {
    flex-basis: 90%;
    margin: auto;
    background-color: $image-overlay;

    .provisions-categoryName {
      @include categoryTitle;

      @include responsive(mobile) {
        font-size: $text-large;
      }
    }

    .provisions-categoryDescription {
      padding-bottom: 0.25rem;
      @include categoryDescription;
    }

    .provisions-categoryListings {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0.25rem;

      .provisions-link {
        display: flex;
        flex-basis: 33%;

        @include responsive(widescreen) {
          flex-basis: 25%;
        }

        @include responsive(tablet) {
          flex-basis: 50%;
        }

        @include responsive(mobile) {
          flex-basis: 100%;
        }

        .provisions-listing {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 0.25rem;
          overflow: hidden;
          transition: all 0.2s linear;
          background-size: 100%;
          background-color: white;
          color: $image-text;
          font-family: $subheading-font;
          font-size: $text-standard;
          font-weight: bold;
          text-decoration: none;
          text-align: center;
          @include imagesMain;

          &:hover {
            background-size: 125%;
            .provisions-provisionTitle {
              background-color: $primary;
            }
          }

          .provisions-provisionTitle {
            padding: 0.1rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            transition: all 0.2s linear;
            @include outline(black, 1px, 3px);
          }

          .provisions-new {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.1rem;
            background-color: $primary;
            font-size: $text-small;
          }
        }
      }
    }
  }
}
