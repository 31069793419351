@import "../../../../Styles/master";

#home {
  background-image: url("../../../../Media/images/wood.jpg");
  padding-bottom: 1rem;
  background-size: 100% auto;
}

.home-container {
  @include wood-size;
}

#home-overview,
.shop {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include pageOverviewText;

  .home-overviewParagraph {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
}
