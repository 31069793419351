@import "../../../../Styles/master";

$max-height: 15rem;

#news {
  .separator {
    background-color: $secondary3;
  }
}

#news-header {
  @include headerImage;
  #news-headerText {
    @include headerText;
  }
}

#news-about {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @include pageOverviewText;
}

#news-search {
  @include search;

  #news-searchIcon {
    @include searchIcon;
  }

  #news-searchInput {
    @include searchBar;
  }

  #news-resultCount {
    @include searchResults;
  }
}

#news-noResults {
  @include noResults;
}

#news-articles {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  background-size: 100vw auto;

  #news-background {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    background-color: $image-overlay;

    @include responsive(tabletSmall) {
      width: 100%;
    }
  }

  .news-articleLink {
    flex-basis: 100%;
    max-width: 100%;

    .news-article {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      max-height: $max-height;
      margin: 0.5rem;
      padding-bottom: 0.75rem;
      border: 4px ridge $primary;
      border-radius: 3px;
      box-sizing: border-box;
      overflow: hidden;
      background-color: $dark-gray;
      color: white;

      .news-articleTitleAndDate {
        flex-basis: 100%;
        background-image: url("../../../../Media/images/wood.jpg");
      }

      .news-articleDate {
        flex-basis: 100%;
        height: 0.5rem;
        padding-top: 0.1rem;
        padding-right: 0.25rem;
        padding-bottom: 8px;
        color: white;
        font-family: $text-font;
        font-size: 0.5rem;
        font-weight: bold;
        text-align: center;
        @include outline(black, 0px, 3px);

        @include responsive(desktop) {
          padding-bottom: 0.25rem;
          padding-left: 0;
          text-align: right;
        }
      }

      .news-articleTitle {
        padding: 0.25rem;
        padding-bottom: 0.25rem;
        background-color: unset !important;
        color: $primary;
        transform: translateY(-8px);
        @include postTitle;
        @include outline(black, 1px, 3px);
      }

      .news-articleImageAndText {
        display: flex;
        flex-wrap: nowrap;
        flex-basis: 100%;
        box-sizing: border-box;

        @include responsive(tabletSmall) {
          flex-wrap: wrap;

          .news-articleImage,
          .news-articleTextContainer {
            flex-basis: 100% !important;
          }

          .news-articleTextContainer {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
          }
        }

        @include responsive(widescreen) {
          .news-articleImage {
            flex-basis: 40% !important;
          }

          .news-articleTextContainer {
            flex-basis: 60% !important;
          }
        }

        .news-articleImage {
          flex-basis: 50%;
        }

        .news-articleTextContainer {
          flex-basis: 50%;
          @include responsive(tabletSmall) {
            flex-basis: 100%;
          }
        }
      }

      .news-articleImage {
        flex-basis: 100%;
        height: 15rem;
        max-height: 15rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @include responsive(tabletSmall) {
          max-height: 6rem;
        }
      }

      .news-articleParagraphs {
        flex-basis: 100%;
        margin: 0.25rem;

        @include responsive(tabletSmall) {
          margin: 0.1rem;
        }

        .news-articleParagraph {
          width: 100% !important;
          color: white !important;
          @include paragraphText;
        }
      }

      .news-articleText,
      .news-articleListItem {
        flex-basis: 100%;
        color: white;
        font-family: $text-font;
        font-size: $text-standard;

        @include responsive(desktop) {
          margin: 0.5rem;
          margin-top: 0.25rem;
        }

        @include responsive(tablet) {
          font-size: $text-small;
        }

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }

      .news-articleListTitle {
        flex-basis: 100%;
        padding-top: 0.25rem;
        padding-left: 0.25rem;
        color: $secondary-text;
        font-family: $subheading-font;
        font-size: $text-large;

        @include responsive(tablet) {
          font-size: $text-standard;
        }
      }

      .news-articleListTitle {
        text-align: center;
      }

      .news-articleList {
        flex-basis: 100%;
        margin: 0;
        padding: 0.25rem;
        padding-left: 0.75rem;
      }

      .news-articleReadMore {
        @include readMore;
      }
    }
  }
}
