@import "../../../Styles/master";

#separator2-container,
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  flex-basis: 100%;
}

#separator2-container {
  background-color: $secondary3;

  #separator-line {
    margin: auto;
    transition: width 0.5s ease-out;
    width: 0%;
    border-bottom: solid 4px $primary;

    &.separator-lineGrown {
      width: 100%;
    }
  }
}
