// imported fonts
@font-face {
  font-family: "Bebas";
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix");
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix")
      format("eot"),
    url("../Fonts/Bebas/11e21f91-5c9d-4170-908d-d6afa916d2fc.woff2")
      format("woff2"),
    url("../Fonts/Bebas/b336f9f4-ee3c-4257-a4af-d5d2e3671b86.woff")
      format("woff"),
    url("../Fonts/Bebas/213f5fa9-0ccb-4f43-baf8-99d81c93b2ae.ttf")
      format("truetype");
}

$media: screen;

$mobileSmall: 375px;
$mobile: 450px;
$tabletSmall: 600px;
$tablet: 700px;
$desktop: 800px;
$stickyLinksCutoff: 850px;
$widescreen: 1100px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobileSmall {
    @media #{$media} and (max-width: $mobileSmall) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tabletSmall {
    @media #{$media} and (max-width: $tabletSmall) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == stickyLinksCutoff {
    @media #{$media} and (max-width: $stickyLinksCutoff) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $widescreen) {
      @content;
    }
  }
}

@mixin wood-size {
  width: 90% !important;
  margin: auto;
  box-shadow: 0 0 5px 2px black;

  @include responsive(mobile) {
    width: 90%;
  }
}

// Standard Style Mixins
@mixin headerImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vw;
  background-position: center;
  background-size: cover;

  @include responsive(desktop) {
    height: 15rem;
  }

  @include responsive(widescreen) {
    background-attachment: fixed;
  }
}

@mixin headerText {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  background-color: #000000a9;
  color: $primary;
  font-family: $heading-font;
  font-size: $text-header;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.5px;
  @include outline(white, 1px, 2px);

  @include responsive(mobile) {
    font-size: $text-section-header;
  }
}

@mixin title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $secondary2;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;
  text-align: center;
}

@mixin pageOverviewText {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  background-color: $secondary3;
  color: $secondary-text;
  font-family: $text-font;
  font-size: $text-standard;
  font-weight: bold;
  text-align: center;

  @include responsive(widescreen) {
    padding-left: 15%;
    padding-right: 15%;
  }

  @include responsive(mobile) {
    padding-left: 3%;
    padding-right: 3%;
    font-size: $text-small;
  }
}

@mixin paragraphText {
  flex-basis: 100%;
  overflow: hidden;
  color: $secondary-text;
  font-family: $text-font;
  font-size: $text-standard;
  text-indent: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @include responsive(tablet) {
    font-size: $text-small;
    text-indent: 0.5rem;
  }

  &:not(:last-child) {
    padding-bottom: 0.25rem;
  }
}

@mixin search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: $secondary3;
}

$corner-radius: 8px;

@mixin searchIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px ridge black;
  border-right: none;
  background-color: $primary;
  color: black;
  font-size: $text-section-header;
  border-top-left-radius: $corner-radius;
  border-bottom-left-radius: $corner-radius;
}

@mixin searchBar {
  flex-basis: 50%;
  padding: 0.25rem;
  border: 2px solid black;
  border-left: none;
  box-sizing: border-box;
  box-sizing: border-box;
  font-family: $text-font;
  font-size: $text-standard;
  border-top-right-radius: $corner-radius;
  border-bottom-right-radius: $corner-radius;

  @include responsive(tabletSmall) {
    flex-basis: 70%;
  }

  @include responsive(mobile) {
    flex-grow: 1;
    margin-right: 0.25rem;
  }
}

@mixin searchResults {
  flex-basis: 100%;
  padding-top: 0.1rem;
  padding-bottom: 0.2rem;
  color: black;
  font-family: $text-font;
  font-size: $text-tiny;
  font-weight: bold;
  text-align: center;
}

@mixin postTitle {
  flex-basis: 100%;
  padding-bottom: 0.25rem;
  background-color: $dark-gray;
  color: $primary-text;
  font-family: $subheading-font;
  font-size: $text-large;
  font-weight: bold;
  text-align: center;

  @include responsive(mobile) {
    font-size: $text-standard;
  }
}

@mixin categoryTitle {
  flex-basis: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  color: $secondary2;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;
  text-align: center;
  @include outline(black, 1px, 3px);
  @include responsive(mobile) {
    font-size: $text-standard;
  }
}

@mixin categoryDescription {
  padding: 0.5rem;
  color: $secondary-text;
  font-family: $text-font;
  font-size: $text-standard;
  font-weight: bold;
  text-align: center;

  @include responsive(mobile) {
    font-size: $text-small;
  }
}

@mixin readMore {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 0.5rem;
  padding-bottom: 0.125rem;
  padding-top: 0.25rem;
  border-top: 2px dashed $primary;
  border-left: 2px dashed $primary;
  border-right: 2px dashed $primary;
  background-color: $dark-gray;
  color: white;
  font-family: $text-font;
  font-size: $text-small;
  font-weight: bold;
  text-align: center;
  transform: translateX(-50%);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

@mixin backButton {
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  padding: 0.1rem;
  border-radius: 3px;
  background-color: $primary;
  color: $primary-text;
  font-family: $text-font;
  font-size: $text-small;
}

@mixin dateWithBackButton {
  margin-left: auto;
  margin-right: 0.5rem;
  font-family: $text-font;
  font-size: $text-small;
}

@mixin itemTitle {
  flex-basis: 100%;
  padding: 0.25rem;
  padding-bottom: 0;
  box-sizing: border-box;
  font-size: $text-section-header;
  @include title;

  @include responsive(tabletSmall) {
    font-size: $text-large;
  }

  @include responsive(mobile) {
    font-size: $text-standard;
  }
}

@mixin imagesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  margin-bottom: auto;
  padding: 0.25rem;
  box-sizing: border-box;

  @include responsive(tabletSmall) {
    flex-basis: 95%;
  }

  @include responsive(widescreen) {
    flex-basis: 40%;
  }
}

@mixin imagesMain {
  flex-basis: 100%;
  height: auto;
  border: 2px solid $dark-gray;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &:before {
    content: "";
    padding-top: 100%;
    float: left;
  }
}

@mixin imagesTiles {
  flex-basis: 20%;
  height: auto;
  border: 2px solid $secondary;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;

  &:not(.imageTileSelected) {
    cursor: pointer;
  }

  &.imageTileSelected,
  &:hover {
    border-top: 2px solid $primary;
  }

  &:before {
    content: "";
    padding-top: 100%;
    float: left;
  }
}

@mixin addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: $primary;
  color: $primary-text;
  font-family: $subheading-font;
  font-size: $text-standard;
  font-weight: bold;

  @include responsive(mobile) {
    font-size: $text-small;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  height: 3rem;
  color: $primary;
  font-family: $subheading-font;
  font-size: $text-section-header;
  font-weight: bold;

  @include responsive(tabletSmall) {
    font-size: $text-large;
  }
}

@mixin instructionList {
  font-weight: normal;
  @include responsive(mobile) {
    margin: 0;
    padding-top: 0.5rem;
    padding-left: 0.75rem;
  }
}

@mixin outline($color, $size, $blur) {
  text-shadow: -$size (-$size) $blur $color, $size (-$size) $blur $color,
    -$size $size $blur $color, $size $size $blur $color;
}

// Typefaces
$text-font: "Red Hat Text";
$link-font: "Bebas";
$heading-font: "Bebas";
$subheading-font: "Raleway";

// Text Sizing
$text-header: 2.5rem;
$text-section-header: 1.5rem;
$text-large: 1rem;
$text-standard: 0.75rem;
$text-small: 0.6rem;
$text-tiny: 0.5rem;
$icon-standard: 1rem;

// Color Palette
$header-background-transparent: #ffffffb0;
$primary: #d85049;
$primary-text: white;
$dark-gray: #181818;
$secondary: white;
$secondary2: #d85049;
$secondary3: #fff5f5;
$secondary-text: black;
$secondary-text-subtle: gray;
$accent: #5c4c4c;
$accent-text: white;
$image-text: #ffffff;
$image-overlay: #ffffff80;
$image-textBackground: #000000b0;
$image-textOutline: -1px -1px 0 $image-textBackground,
  1px -1px 0 $image-textBackground, -1px 1px 0 $image-textBackground,
  1px 1px 0 $image-textBackground;
$image-textOutlineWhite: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
  1px 1px 0 white;

.content-linkText {
  transition: all 0.3s linear;
  color: $accent;
  font-weight: bold;
  text-decoration: underline $primary;
  letter-spacing: 1px;
  @include outline($primary, 0px, 1px);

  &:hover {
    color: $primary;
    text-decoration: underline black;
    @include outline(black, 0px, 1px);
  }
}
