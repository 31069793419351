@import "../../../Styles/master";

.article {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.article-header {
  display: flex;
  align-items: center;
  padding-top: 0.25rem;
  flex-basis: 100%;

  @include responsive(widescreen) {
    flex-basis: 90%;
  }

  @include responsive(mobile) {
    padding-bottom: 0.25rem;
  }

  .article-backLink {
    text-decoration: none;

    .article-back {
      @include backButton;
    }

    .article-backIcon {
      font-size: $text-large;
      color: $primary-text;
    }
  }

  .article-date {
    @include dateWithBackButton;
  }
}

.article-title {
  @include itemTitle;
}

.article-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 100%;
  overflow: hidden;

  .article-images {
    @include imagesContainer;
    flex-basis: 100% !important;
    background-image: url("../../../Media/images/wood.jpg");
    background-size: 100% auto;

    border-top: 4px ridge $primary;
    border-bottom: 4px ridge $primary;
    .article-imageMain {
      @include imagesMain;
      max-width: 500px;
      margin: auto;
    }

    .article-imageTiles {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-basis: 100%;

      .article-imageTile {
        @include imagesTiles;
        max-width: 4rem;
      }
    }
  }

  .article-textContent {
    box-sizing: border-box;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    flex-basis: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include responsive(widescreen) {
      flex-basis: 90%;
    }

    &.article-textContentFullWidth {
      flex-basis: 100%;
      box-sizing: border-box;
    }

    .article-paragraph {
      &.article-paragraphFullWidthOnly {
        height: 0;
      }

      &.article-componentFullWidth {
        padding-bottom: 0;
      }

      .article-componentFullWidth {
        width: 0px;
        height: 0px;
        display: inline-block;
        overflow: hidden;
        position: absolute;
      }
    }

    .article-listTitle,
    .article-listItem {
      &.article-componentFullWidth {
        display: none;
      }
    }
  }

  .article-text {
    box-sizing: border-box;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    flex-basis: 100%;
  }

  .article-paragraph {
    @include paragraphText;
  }

  .article-listContainer {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .article-listTitle {
    padding-top: 0.5rem;
    text-align: center;
    font-family: $subheading-font;
    font-size: $text-large;
    color: $secondary-text;
    flex-basis: 100%;

    @include responsive(tabletSmall) {
      font-size: $text-standard;
    }
  }

  .article-list {
    margin: 0;
    padding-top: 0.5rem;
    @include instructionList;
  }

  .article-listItem {
    color: $secondary-text;
    font-family: $text-font;
    font-size: $text-standard;
    text-align: left;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;

    @include responsive(mobile) {
      font-size: $text-small;
    }
  }

  .article-fullWidth {
    margin: 0.25rem;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    :not(.article-componentFullWidth) {
      display: none;
    }

    .article-paragraph {
      text-indent: 0 !important;
      :first-child.article-componentFullWidth {
        padding-left: 1rem;
      }
    }
  }
}
