@import "../../../Styles/master";

#separator-container,
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  flex-basis: 100%;
}

#separator-container {
  padding-top: 1rem;
  padding-bottom: 1rem;

  #separator-line {
    transition: width 0.5s ease-out;
    width: 0%;
    border-bottom: solid 4px $primary;

    &.separator-lineGrown {
      width: 40%;

      @include responsive(tablet) {
        width: 50%;
      }

      @include responsive(mobile) {
        width: 80% !important;
      }
    }
  }
}
