@import "../../../Styles/master";

.bam {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-grow: 1;

  @include responsive(tablet) {
    flex-basis: 100%;
  }

  .bam-container {
    text-decoration: none;
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 0.25rem;

    @include responsive(tablet) {
      margin-left: 0;
    }

    &:hover {
      .bam-text {
        color: $secondary2;
      }
    }

    .bam-text {
      font-family: "Bangers";
      font-size: 0.75rem;
      color: black;
      text-align: right;
      padding-right: 0.25rem;
      transition: color 0.3s linear;
      // padding-bottom: 0.25rem;
      letter-spacing: 0.75px;
      @include outline(white, 1px, 3px);

      @include responsive(tablet) {
        font-size: 0.5rem;
        color: $secondary2;
        padding-bottom: 0.25rem;
      }
    }

    .bam-logo {
      width: auto;
      height: 1.5rem;
    }
  }
}
